import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { TooltipPosition } from '@angular/material/tooltip';
import * as _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';

export interface ModelDefinitionsModel {
    placeholder?: string;
    dataSource: any;
    fields?: {text: string, value: string};
}


@Component({
    selector: 'tsng-form-group-input',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './form-group-input.component.html',
    styleUrls: ['form-group-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: FormGroupInputComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => FormGroupInputComponent),
        },
    ],
})
export class FormGroupInputComponent
    implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy, Validator, OnChanges {
    @ViewChild('editInput') editInput!: ElementRef<HTMLDivElement>;
    @ViewChild('NAButton') NAButton!: MatButtonToggle;
    @ViewChild('NDButton') NDButton!: MatButtonToggle;
    @Input() id = '';
    @Input() label = '';
    @Input() type = 'text';
    @Input() value = '';
    @Input() prefix = '';
    @Input() tooltip = '';
    @Input() appendTo = '';
    @Input() TooltipPosition:TooltipPosition = 'below';
    @Input() suffix = '';
    @Input() disabled = false;
    @Input() autocomplete = 'off';
    @Input() required = false;
    @Input() multiline = false;
    @Input() multiple = false;
    @Input() placeholder = '';
    @Input() hint = '';
    @Input() NA = undefined;
    @Input() ND = undefined;
    @Input() minuteSteps:number = 1;
    @Input() hoursFormat = 24;
    @Input() min:number | undefined = undefined;
    @Input() max:number | undefined = undefined;
    @Input() forceSelection:boolean = true;
    @Input() model:ModelDefinitionsModel = {
        placeholder: 'Entrez une valeur',
        dataSource: [],
        fields: { text: 'text', value: 'value' },
    };
    @Input('class') classes = '';
    @Input('ngInputStyle') inputStyles!:{ [klass: string]: any; };
    
    // Ouputs
    @Output() valueChange = new EventEmitter<any>();
    @Output() change = new EventEmitter<any>();
    @Output() blur = new EventEmitter<any>();
    
    
    public dateValue: Date = new Date();
    public valueComboText = '';
    public monControle = new FormControl('');
    public hasErrors:string[] = [];

    onChangeSubs: Subscription[] = [];

    constructor(private ref: ChangeDetectorRef) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            this.value = changes.value.currentValue;
            // if (this.type === 'DateTime') {
            //     this.dateValue = moment(this.value).utc().toDate();
            //     this.type = 'datetime-local';
            // }
            if ((this.type === 'Time' || this.type === 'Date') && changes.value.currentValue !== undefined) {
                this.value = moment(this.value).utc(true).toISOString()
            }
            this.monControle.setValue(this.value);
            this.writeValue(this.value);
        }
    }

    ngAfterViewInit(): void {
        // this.disabled ? this.monControle.disable() : this.monControle.enable();
        this.monControle.valueChanges.subscribe( v => {
            // if (this.type === 'Date' && this.monControle.value) {
            //     console.log('monControle.valueChanges', this.monControle.value)
            //     this.monControle.setValue(moment(this.monControle.value).utc().toDate(), {emitEvent: false})
            //     console.log('monControle.valueChanges', this.monControle.value)
            // }

            this.valueChange.emit(this.monControle.value);
            this.change.emit(this.monControle.value);
        })
    }

    ngOnInit() {
        this.valueComboText = this.value;
        if (this.placeholder) {
            this.model.placeholder = this.placeholder;
        }

        if (this.type.toLowerCase() === 'textarea') {
            this.type = 'textarea';
            this.multiline = true;
        }

        if (this.type === 'Numeric') {
            this.type = 'number';
        }

        if (this.type === 'DateTime') {
            this.dateValue = moment(this.value).utc().toDate();
            this.type = 'datetime-local';
        }

        if (this.type === 'Date') {
            this.dateValue = moment(this.value).utc().toDate();
        }

        if (this.type === 'ComboBox') {
            if (this.model && this.model.dataSource instanceof Array) {
                if (!this.model.fields) {
                    this.model.fields = { text: 'text', value: 'value' };
                }
                _.each(this.model.dataSource, i => {
                    if (i[this.model.fields!.value] === this.value) {
                        this.valueComboText = i[this.model.fields!.text];
                        this.value = i[this.model.fields!.value];
                    }
                });
            }
        }

        if (this.type === 'Time') {
            // Valider si on recoit une date ou bien juste une string de l'heure
            if (this.value && this.value !== null) {
                if (moment(this.value).isValid()) {
                    this.value = moment(this.value).utc().format('HH:mm');
                } else {
                    this.value = this.value;
                }

            } else {
                this.value = '';
            }
        }

        this.monControle.setValue(this.value);
        if (this.required) {
            this.label += " *"
            this.monControle.setValidators([Validators.required]);
            this.monControle.updateValueAndValidity()
        }

    }

    onChange(event: any) {
        // console.log('onChange', event, this.monControle.value)
        this.value = this.monControle.value;

        if (this.type == 'ComboBox') {
            // Aller chercher la valeur text du choix
            const val = _.find(this.model.dataSource, [
                this.model.fields!.value,
                this.monControle.value,
            ]);
            this.valueComboText = val ? val[this.model.fields!.text] : '';
        } else if (this.type === 'Date') {
            this.monControle.setValue(moment(moment(this.monControle.value).utc().format("YYYY-MM-DD 00:00:00")).toDate(), {emitEvent: true})
            this.value = this.monControle.value;
        }

        this.onTouched();

    }

    onChangeTime(event: any) {
        this.value = event;
        this.change.emit(event);
        this.valueChange.emit(event);
        this.onTouched();
    }

    clearValue(event: any) {
        this.monControle.setValue(null, {emitEvent: true, onlySelf: true});
        this.change.emit(this.monControle.value);
        this.valueChange.emit(this.monControle.value);
    }

    onSearch(event: any) {
        if (this.forceSelection === false) {
            // On permet de modifier la valeur parce qu'on ne force pas la sélection de la liste
            this.monControle.setValue(event.term, {emitEvent: true, onlySelf: true});
        }
    }

    onBlur(event: any) {
        this.blur.emit(this.monControle.value);
    }

    onFocus() {
        this.monControle.markAsTouched()
        this.validate(this.monControle);
    }

    onTouched: Function = () => {
        this.monControle.markAsTouched()
        this.validate(this.monControle);
    };

    onValidatorChanged: Function = () => {
    };

    writeValue(value: any): void {
        try {
            if (value !== undefined) {
                if (this.type === 'Time') {
                    // Valider si on recoit une date ou bien juste une string de l'heure
                    if (value && value !== null && value !== 'Invalid date') {
                        if (value.length>10 && moment(value).isValid()) {
                            this.value = moment(value).utc().format('HH:mm');
                        } else {
                            this.value = value;
                        }
                        
                    } else {
                        this.value = '';
                    }
                    this.monControle.setValue(this.value, { emitEvent: true });
                } else if (this.type === 'Date') {
                    this.dateValue = moment(value).utc().toDate();
                    // this.value = value;
                    
                    // Il faut retirer le Z dans le timezone!
                    this.value = moment(value).utc().format("YYYY-MM-DDT00:00:00");
                    this.monControle.setValue(this.value, { emitEvent: true });
                } else {
                    this.value = value;
                    this.monControle.setValue(this.value, { emitEvent: true });
                }

                if (this.type === 'ComboBox') {
                    // Aller chercher la valeur text du choix
                    let val;
                    if (this.model.fields) {
                        val = _.find(this.model.dataSource, [
                            this.model.fields.value,
                            this.monControle.value,
                        ]);
                        this.valueComboText = val ? val[this.model.fields.text] : '';
                    } else {
                        val = this.model.dataSource[this.monControle.value];
                        this.valueComboText = val ? val : '';
                    }
                }

                // On check pour NA/ND
                if (this.ND !== undefined && (this.type === 'Info' || this.disabled)) {
                    if (this.value == this.ND) {
                        this.value = 'ND';
                    }
                }
                if (this.NA !== undefined && (this.type === 'Info' || this.disabled)) {
                    if (this.value == this.NA) {
                        this.value = 'NA';
                    }
                }
                // if (this.ND !== undefined && this.monControle.value == this.ND) {
                //     this.NDButton.checked = true
                // }

                // Forcer un redraw
                this.ref.markForCheck();

            }

        } catch(error) {
            console.error('writeValue', value, error);
        }

    }
    registerOnChange(fn: any): void {
        const sub = this.monControle.valueChanges.subscribe(fn);
        this.onChangeSubs.push(sub);
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    registerOnValidatorChange(fn: any): void {
        this.onValidatorChanged = fn;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        // Si notre controle a des validators, on va l'appliquer à notre sous-controle
        // console.log('validate', this.label, this.monControle.touched, this.monControle.errors, hasRequired)
        
        const hasRequired = control.hasValidator(Validators.required)
        if (hasRequired) {
            //C'est bizarre le fonctionnement de "required", on doit patcher ça l'air!
            if (!this.monControle.hasValidator(Validators.required)) {
                // On ajoute manuellement le validator sur le controle.
                this.monControle.addValidators(Validators.required);
                this.monControle.updateValueAndValidity()
                this.label += " *"
            }
        }

        if (control.validator && this.monControle.touched) {
            let errors = control.validator(this.monControle);
            this.hasErrors = [];
            this.monControle.setErrors(errors);
            // console.log('validate errors', this.label, errors, this.monControle.touched)
            if (errors && this.monControle.touched) {
                _.each(errors, (err, type) => {
                    switch (type.toLowerCase()) {
                        case 'minlength':
                            this.hasErrors.push(`Un minimum de ${err.requiredLength} caractères est requis`);
                            break;

                        case 'maxlength':
                            this.hasErrors.push(`Un maximum de ${err.requiredLength} caractères est permis`);
                            break;

                        case 'required':
                            this.hasErrors.push(`Le champ est obligatoire`);
                            break;
                    
                        case 'min':
                            this.hasErrors.push(`Le champ doit avoir une valeur minimum de ${err.min}`);
                            break;
                    
                        case 'max':
                            this.hasErrors.push(`Le champ doit avoir une valeur maximale de ${err.max}`);
                            break;
                    
                        default:
                            this.hasErrors.push(`Le champ n'est pas valide`);
                            console.error('Form-Group-Input Validation error : ', type, err);
                            break;
                    }
                })
            }
            return errors;
        } else {
            return null;
        }
    }

    setDisabledState(isDisable: boolean) {
        if (isDisable) {
            this.monControle.disable();
        } else {
            this.monControle.enable();
        }

        this.disabled = isDisable;
        this.ref.markForCheck();
    }

    // Clean up subscription
    ngOnDestroy(): void {
        for (const sub of this.onChangeSubs) {
            sub.unsubscribe();
        }
    }

    clickNA(ToggleButton:MatButtonToggle) {
        if (this.monControle.value === this.NA) {
            // Unselect
            ToggleButton.checked = false;
            this.monControle.setValue('');
            this.monControle.enable()
        } else {
            this.monControle.setValue(this.NA)
            this.monControle.disable()
        }
    }
    
    clickND(ToggleButton:MatButtonToggle) {
        if (this.monControle.value === this.ND) {
            // Unselect
            ToggleButton.checked = false;
            this.monControle.setValue('');
            this.monControle.enable()
        } else {
            this.monControle.setValue(this.ND)
            this.monControle.disable()
        }
    }
}
