/* All feather icons -> https://github.com/michaelbazos/angular-feather#available-icons */

import {
    Activity,
    AlertTriangle,
    Archive,
    ArrowLeftCircle,
    ArrowRight,
    ArrowUpLeft,
    Award,
    BarChart,
    Bell,
    Book,
    Bookmark,
    BookOpen,
    Calendar,
    Check,
    CheckCircle,
    ChevronDown,
    ChevronRight,
    ChevronUp,
    Circle,
    Clipboard,
    CloudRain,
    Code,
    Copy,
    CornerRightDown,
    Database,
    Download,
    DollarSign,
    Droplet,
    Edit,
    Edit3,
    ExternalLink,
    Eye,
    Feather,
    File,
    FileText,
    Film,
    Filter,
    Flag,
    Globe,
    Heart,
    HelpCircle,
    Home,
    Info,
    Layers,
    Layout,
    Link,
    Lock,
    LogOut,
    Mail,
    Maximize,
    Menu,
    Moon,
    MoreVertical,
    MousePointer,
    Package,
    PieChart,
    PlusCircle,
    Printer,
    RefreshCcw,
    RefreshCw,
    Repeat,
    Save,
    Search,
    Settings,
    Share,
    ShoppingCart,
    Sidebar,
    Sliders,
    Sun,
    Sunset,
    Tool,
    Trash2,
    TrendingDown,
    TrendingUp,
    Type,
    User,
    UserPlus,
    Users,
    X,
    XCircle,
    XSquare,
    Zap,
    ZapOff,
} from 'angular-feather/icons';

export const featherIcons = {
    Activity,
    AlertTriangle,
    Archive,
    ArrowLeftCircle,
    ArrowUpLeft,
    BarChart,
    Award,
    ArrowRight,
    Bell,
    Book,
    Bookmark,
    BookOpen,
    Calendar,
    Check,
    CheckCircle,
    ChevronUp,
    ChevronDown,
    ChevronRight,
    Circle,
    Clipboard,
    CloudRain,
    Code,
    Copy,
    CornerRightDown,
    Database,
    DollarSign,
    Download,
    Droplet,
    Edit,
    Edit3,
    ExternalLink,
    Eye,
    Feather,
    File,
    FileText,
    Film,
    Filter,
    Flag,
    Globe,
    Heart,
    HelpCircle,
    Home,
    Info,
    Layers,
    Layout,
    Link,
    Lock,
    LogOut,
    Mail,
    Maximize,
    Menu,
    Moon,
    MoreVertical,
    MousePointer,
    Package,
    PieChart,
    PlusCircle,
    Printer,
    RefreshCcw,
    RefreshCw,
    Repeat,
    Save,
    Search,
    Settings,
    Share,
    ShoppingCart,
    Sidebar,
    Sliders,
    Sun,
    Sunset,
    Tool,
    Trash2,
    TrendingDown,
    TrendingUp,
    Type,
    User,
    UserPlus,
    Users,
    XCircle,
    X,
    XSquare,
    Zap,
    ZapOff,
};
