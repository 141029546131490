<span class="mycontainer" *ngIf="!disabled &amp;&amp; type !== 'Info'"
    ><ng-container *ngIf="type === 'ComboBox'"
        ><div class="my-ng-container" [class]="classes"><span class="prefix" *ngIf="prefix" matPrefix>{{ prefix }}</span><ng-select class="d-inline w-100 ng-select-custom" [multiple]="multiple" [formControl]="monControle" [items]="model.dataSource" [bindLabel]="model.fields!.text" [bindValue]="model.fields!.value" [placeholder]="label" [appendTo]="appendTo" typeToSearchText="Entrez une valeur" [selectOnTab]="true" [attr.data-cy]="id" [clearSearchOnAdd]="true" [ngStyle]="inputStyles" (blur)="onBlur($event)" (change)="onChange($event)" (search)="onSearch($event)" [closeOnSelect]="(multiple ? false : true)" notFoundText="Aucun item trouvé"></ng-select><mat-hint *ngIf="hint">{{ hint }}</mat-hint><span class="suffix" *ngIf="suffix" matSuffix>&nbsp; {{ suffix }}</span></div>
        <div class="error" *ngFor="let err of hasErrors">{{ err }}</div></ng-container
    ><mat-form-field class="my-field-wrapper" *ngIf="type !== 'ComboBox'" [class]="classes" [hintLabel]="hint"
        ><mat-label *ngIf="label">{{ label }} :</mat-label><mat-error *ngFor="let err of hasErrors">{{ err }}</mat-error><span class="prefix" *ngIf="prefix" matPrefix>{{ prefix }}</span><input class="form-control" [type]="type" [class.text-primary]="(inputStyles === undefined || (inputStyles.color || '').length === 0)" [class.d-none]="(NA !== undefined &amp;&amp; monControle.value == NA) || (ND !== undefined &amp;&amp; monControle.value == ND)" *ngIf="(type !== 'Time' &amp;&amp; type !== 'Date' &amp;&amp; type !== 'textarea' &amp;&amp; type !== 'ComboBox')" #editInput matInput [id]="id" [attr.data-cy]="id" [value]="value" [min]="min" [max]="max" [required]="required" [autocomplete]="autocomplete" [formControl]="monControle" [ngStyle]="inputStyles" (blur)="onBlur($event)" (change)="onChange($event)" (focus)="onFocus()" /><input
            class="form-control text-primary"
            [type]="'datetime-local'"
            *ngIf="type == 'DateTime'"
            #editInput
            matInput
            [id]="id"
            [attr.data-cy]="id"
            [value]="value"
            [required]="required"
            [formControl]="monControle"
            [disabled]="disabled"
            [ngStyle]="inputStyles"
            (blur)="onBlur($event)"
            (focus)="onFocus()"
            (change)="onChange($event)"
        /><input class="form-control text-primary" *ngIf="type === 'Date'" matInput [matDatepicker]="picker" [id]="id" [required]="required" [autocomplete]="autocomplete" [formControl]="monControle" [ngStyle]="inputStyles" (blur)="onBlur($event)" (change)="onChange($event)" /><mat-datepicker-toggle *ngIf="type == 'Date'" matSuffix [for]="picker"></mat-datepicker-toggle><mat-datepicker #picker></mat-datepicker
        ><ng-container *ngIf="NA !== undefined || ND !== undefined"
            ><mat-button-toggle-group [ngStyle]="inputStyles" appearance="legacy"><mat-button-toggle *ngIf="NA !== undefined" matSuffix #NAButton aria-label="NA" [checked]="monControle.value == NA" (change)="clickNA(NAButton)" [class.btn-primary]="monControle.value == NA">NA</mat-button-toggle><mat-button-toggle *ngIf="ND !== undefined" matSuffix #NDButton aria-label="ND" [checked]="monControle.value == ND" (change)="clickND(NDButton)" [class.btn-danger]="monControle.value == ND">ND</mat-button-toggle></mat-button-toggle-group></ng-container
        ><ng-container *ngIf="type === 'Time'"
            ><ng-container *ngIf="minuteSteps !== 1"><input class="form-control text-primary" matInput [id]="id" [attr.data-cy]="id" [required]="required" [autocomplete]="autocomplete" [formControl]="monControle" (change)="onChange($event)" [ngxTimepicker]="timepicker" [ngStyle]="inputStyles" (blur)="onBlur($event)" (focus)="onFocus()" [format]="hoursFormat" /><ngx-material-timepicker-toggle *ngIf="type === 'Time'" matSuffix [for]="timepicker"></ngx-material-timepicker-toggle><ngx-material-timepicker #timepicker [minutesGap]="minuteSteps" (blur)="onBlur($event)" (timeChanged)="onChangeTime($event)"></ngx-material-timepicker></ng-container
            ><ng-container *ngIf="minuteSteps === 1"><input class="form-control text-primary" matInput [id]="id" [attr.data-cy]="id" [required]="required" [autocomplete]="autocomplete" [formControl]="monControle" (change)="onChange($event)" [ngxTimepicker]="timepicker" [ngStyle]="inputStyles" (blur)="onBlur($event)" (focus)="onFocus()" [format]="hoursFormat" /><ngx-material-timepicker-toggle *ngIf="type === 'Time'" matSuffix [for]="timepicker"></ngx-material-timepicker-toggle><ngx-material-timepicker #timepicker (blur)="onBlur($event)" (timeChanged)="onChangeTime($event)"></ngx-material-timepicker></ng-container></ng-container
        ><textarea class="form-control text-primary" *ngIf="type == 'textarea'" #editInput matInput [id]="id" [attr.data-cy]="id" [required]="required" [formControl]="monControle" [ngStyle]="inputStyles" (blur)="onBlur($event)" (change)="onChange($event)" (focus)="onTouched()" cdkTextareaAutosize>{{ value }}</textarea><a class="close" mat-button *ngIf="value &amp;&amp; type !== 'Time'" matSuffix mat-icon-button aria-label="Vider" (click)="clearValue($event)"><i-feather class="text-default" name="x-square" mat-icon></i-feather></a><span class="suffix" *ngIf="suffix" matSuffix>&nbsp; {{ suffix }}</span></mat-form-field
    ></span
><span class="mycontainer" *ngIf="(type === 'Info' || disabled)" [ngStyle]="inputStyles"><mat-label class="pr-2" *ngIf="label">{{ label }} :</mat-label><span class="prefix" *ngIf="prefix" matPrefix>{{ prefix }}</span><span class="text-primary" *ngIf="type !== 'ComboBox' &amp;&amp; type !== 'Date'" [matTooltip]="tooltip" [matTooltipPosition]="TooltipPosition" [innerHTML]="value | nl2br"></span><span class="text-primary" *ngIf="type === 'Date'" [matTooltip]="tooltip" [matTooltipPosition]="TooltipPosition">{{ dateValue | date : 'dd-MM-YYYY' }}</span><span class="text-primary" *ngIf="type === 'ComboBox'" [matTooltip]="tooltip" [matTooltipPosition]="TooltipPosition">{{ valueComboText }}</span><span class="text-primary suffix" *ngIf="suffix" matSuffix>&nbsp; {{ suffix }}</span></span
><ng-content></ng-content>
