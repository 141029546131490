import { Directive, ElementRef, HostListener } from "@angular/core";
import { SimpleGridComponent } from "../simple-grid/simple-grid.component";

@Directive( {
    selector : 'tsng-simple-grid[tsngSimpleGridExport]'
})
export class SimpleGridExport {
    @HostListener('onExport') OnExport() {
        console.log('HostListener OnExport');
    }

    constructor(private myGrid:SimpleGridComponent) {
        console.log('Voici mon component', this.myGrid)
        this.myGrid.allowExport = true;
    }
}