import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
    selector: 'tsng-spinner',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './spinner.component.html',
    styleUrls: ['spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
    @Input() color: ThemePalette = 'warn';
    @Input() mode: ProgressSpinnerMode = 'indeterminate';
    @Input() value = 10;

    constructor() {}
    ngOnInit() {}
}
