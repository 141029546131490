<div class="container-fluid p-0 m-0" [id]="id">
    <div class="d-flex flex-row justify-content-between d-print-none">
        <mat-form-field class="w-50" [class.d-none]="!showFilter"
            ><mat-label>Filtre</mat-label><input matInput (keyup)="applyFilter($event)" placeholder="Ex. Jean" #inputFilter autocomplete="off" /><button *ngIf="inputFilter.value" matSuffix mat-icon-button aria-label="Effacer" (click)="clearFilter()"><mat-icon>close</mat-icon></button></mat-form-field
        >
        <div><button class="btn btn-primary btn-sm" *ngIf="disabled === false &amp;&amp; allowAdd === true" (click)="onAddClick()">Ajouter</button><button class="btn btn-primary btn-sm ml-2" *ngIf="allowExport === true" (click)="onExportClick()">Exporter Excel</button></div>
    </div>
    <mat-button-toggle-group class="d-print-none mb-1" #toggleFilter *ngIf="quickFilters.length &gt; 0" [value]="selectedFilter" (change)="applyQuickFilter2($event)" [class]="quickFliterClasses"><mat-button-toggle *ngFor="let f of quickFilters" [value]="f" [class]="f.class">{{ f.label }}</mat-button-toggle></mat-button-toggle-group>
    <table class="mat-elevation-z1" mat-table cdkDropList (cdkDropListDropped)="drop($event)" cdkDropListData="dataSource" [cdkDropListDisabled]="dragDisabled" [dataSource]="dataSource" [width]="width" matSort (matSortChange)="sortChanged($event)">
        <ng-container matColumnDef="_select_"
            ><th class="selectCol" mat-header-cell *matHeaderCellDef><mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() &amp;&amp; isAllSelected()" [indeterminate]="selection.hasValue() &amp;&amp; !isAllSelected()"></mat-checkbox></th>
            <td class="selectCol" mat-cell *matCellDef="let element"><mat-checkbox (click)="$event.stopPropagation()" (change)="onSelectionChange($event, element)" [checked]="selection.isSelected(element)"></mat-checkbox></td></ng-container
        ><ng-container *ngIf="allowReorder" matColumnDef="_drag_"
            ><th mat-header-cell *matHeaderCellDef>&nbsp;</th>
            <td mat-cell *matCellDef="let element"><mat-icon class="dragCursor" (mousedown)="dragDisabled = false;">reorder</mat-icon></td></ng-container
        ><ng-container *ngFor="let cd of getSortable()" [matColumnDef]="cd.name"
            ><th mat-header-cell *matHeaderCellDef mat-sort-header [arrowPosition]="cd.arrowPosition">{{ cd.headerText}}</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="!cd.showAsPills &amp;&amp; !cd.template">{{ RenderElement(element[cd.name], cd, element) }}</div>
                <ng-container *ngIf="!cd.showAsPills &amp;&amp; cd.template"><ng-container *ngTemplateOutlet="cd.template; context: { data : element}"></ng-container></ng-container><tsng-display-info *ngIf="cd.showAsPills" [list]="element[cd.name]"></tsng-display-info></td></ng-container
        ><ng-container *ngFor="let cd of getNotSortable()" [matColumnDef]="cd.name"
            ><th mat-header-cell *matHeaderCellDef [attr.width]="(cd.name === '_action_' ? getActionColWidth() : '')">{{ cd.headerText}}</th>
            <td mat-cell *matCellDef="let element; let i = index" [class.action]="cd.name === '_action_'">
                <span *ngIf="cd.name !== '_action_'"
                    ><div *ngIf="!cd.showAsPills &amp;&amp; !cd.template" [innerHTML]="RenderElement(element[cd.name], cd, element)"></div>
                    <ng-container *ngIf="!cd.showAsPills &amp;&amp; cd.template"><ng-container *ngTemplateOutlet="cd.template; context: { data : element}"></ng-container></ng-container><tsng-display-info *ngIf="cd.showAsPills" [list]="element[cd.name]"></tsng-display-info></span
                ><span *ngIf="cd.name === '_action_' &amp;&amp; disabled === false &amp;&amp; showAction"
                    ><button class="mr-2" *ngIf="allowEdit" mat-mini-fab color="primary" (click)="action(element, 'edit', $event, i)" matTooltip="Modifier"><i-feather name="edit-3"></i-feather></button><button class="mr-2" *ngIf="allowDuplicate" mat-mini-fab color="primary" (click)="action(element, 'duplicate', $event, i)" matTooltip="Dupliquer"><i-feather name="copy"></i-feather></button><button class="mr-2" *ngIf="allowPrint" mat-mini-fab color="primary" (click)="action(element, 'print', $event, i)" matTooltip="Imprimer"><i-feather name="printer"></i-feather></button><button *ngIf="allowDelete" mat-mini-fab color="warn" (click)="action(element, 'delete', $event, i)" matTooltip="Effacer"><i-feather name="trash-2"></i-feather></button
                ></span></td
        ></ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr #curMatRow mat-row cdkDrag [cdkDragData]="row" [class.selectable]="disabled === false" [ngClass]="getRowClass(row, curMatRow)" *matRowDef="let row; columns: displayedColumns; let i = index;" (click)="onClick(row, $event, i)"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="100%">
                <span *ngIf="!loading"
                    >Aucune donnée disponible<span *ngIf="inputFilter.value.length &gt; 0">&nbsp;ne correspond au filtre <span class="h4 bg-warning">{{ inputFilter.value }}</span></span></span
                ><tsng-spinner *ngIf="loading"></tsng-spinner>
            </td>
        </tr>
    </table>
    <mat-paginator [class.d-none]="(data.length &lt; pageSize)" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" showFirstLastButtons (page)="pageChanged($event)"></mat-paginator>
</div>
