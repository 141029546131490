import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icons } from 'angular-feather/lib/icons.provider';

@Component({
    selector: 'tsng-display-info',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './display-info.component.html',
    styleUrls: ['display-info.component.scss'],
})
export class DisplayInfoComponent implements OnInit, OnChanges {
    @Input() label = '';
    @Input() type = 'text';
    @Input() icon?:IconProp;
    @Input() list: string | string[] = [];
    @Input() text: any;
    @Input() innerHTML: string | undefined = undefined;
    @Input() hideIfEmpty = false;
    @Input() prefix = '';
    @Input() tooltip = '';
    @Input() TooltipPosition:TooltipPosition = 'below';
    @Input() suffix = '';
    @Input() id = '';
    
    public itemList: string[] = [];

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.text && changes.text.currentValue) {
            this.text = changes.text.currentValue.toString();
        }
    }

    ngOnInit() {
        if (this.text === undefined || this.text === null) {
            this.text = '';
        }

        if (this.icon === undefined) {
            // this.icon = [];
        }

        if (typeof this.list === 'string') {
            // Convertir en array
            if (this.list.length) {
                this.list = this.list.replace(/,/g, '|').split('|');
                this.itemList = this.list;
            }
        } else {
            this.itemList = this.list
        }

        if (this.list === undefined) {
            this.list = [];
            this.itemList = [];
        }
        if (this.itemList === undefined) {
            this.itemList = [];
        }

        if (typeof this.text !== 'string') {
            this.text = this.text.toString();
        }
    }
}
