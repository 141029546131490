import { NgModule } from '@angular/core';
import { TsNgLibComponent } from './ts-ng-lib.component';
import { SimpleGridComponent } from './simple-grid/simple-grid.component';
import { DialogConfirmationComponent } from './dialog-confirmation/dialog-confirmation.component';

/* Material */
import { ScrollingModule } from '@angular/cdk/scrolling';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';

import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SpinnerComponent } from './spinner/spinner.component';
import { FeatherModule } from 'angular-feather';
import { featherIcons } from './icons.feather';
import { FormGroupInputComponent } from './form-group-input/form-group-input.component';
import { DisplayInfoComponent } from './display-info/display-info.component';
import { nl2brPipe } from './pipes/nl2br.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fontAwesomeSolidIcons } from './icons.font-awesome-solid';
import { fontAwesomeRegularIcons } from './icons.font-awesome-regular';
import { fontAwesomeBrandsIcons } from './icons.font-awesome-brands';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SimpleGridExport } from './directives/simple-grid-export.directive';
import { ObserversModule } from '@angular/cdk/observers';

/* Configurations */
/*
export const MOMENT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DDMMYYYY'
  },
  display: {
    // dateInput: 'DD-MM-YYYY',
    dateInput: 'MM-YYYY-MM-DD',
    monthYearLabel: 'MMMM Y',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM Y'
  }
};
*/
export const MOMENT_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: ['DD-MM-YYYY', 'YYYY-MM-DD']
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMMM Y',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM Y'
  }
};

@NgModule({
  declarations: [
    TsNgLibComponent,
    SimpleGridComponent,
    DialogConfirmationComponent,
    SpinnerComponent,
    FormGroupInputComponent,
    DisplayInfoComponent,
    nl2brPipe,
    ReplacePipe,
    SimpleGridExport
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,    

    // Material
    DragDropModule,
    ScrollingModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,

    // CDK
    ObserversModule,

    NgxMaterialTimepickerModule,
    NgSelectModule,

    FontAwesomeModule,
    FeatherModule.pick(featherIcons)

  ],
  providers : [
    nl2brPipe,
    ReplacePipe,
    SimpleGridExport,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-CA'},
    { provide: MAT_DATE_FORMATS, useValue: MOMENT_DATE_FORMATS},
    //{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {strict: true, useUtc: true}},
  ],
  exports: [
    TsNgLibComponent,
    SimpleGridComponent,
    DialogConfirmationComponent,
    SpinnerComponent,
    FormGroupInputComponent,
    DisplayInfoComponent,
    nl2brPipe,
    ReplacePipe,
    SimpleGridExport
  ]
})
export class TsNgLibModule { 
  constructor(library: FaIconLibrary) {
    library.addIconPacks(
        fontAwesomeSolidIcons,
        fontAwesomeRegularIcons,
        fontAwesomeBrandsIcons
    );
}
}
