import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tsng-lib-ts-ng-lib',
  template: `
    <p>
      ts-ng-lib works #1! 
    </p>
  `,
  styles: [
  ]
})
export class TsNgLibComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
