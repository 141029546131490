import { ChangeDetectionStrategy, ElementRef, Inject, Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'tsng-dialog-confirmation',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['dialog-confirmation.component.scss'],
})
export class DialogConfirmationComponent implements OnInit {
  @Input() header = 'Confirmer';
  @Input() message = 'Êtes-vous certain de vouloir effacer cet item?';
  @Input() button1 = 'Effacer';
  @Input() button2 = 'Annuler';
  
  constructor(@Inject(MAT_DIALOG_DATA) args: any, private dialogRef: MatDialogRef<DialogConfirmationComponent>, private el:ElementRef ) {
      if (args.header) this.header = args.header
      if (args.message) this.message = args.message.replace(/\n/g, '<br/>')
      if (args.button1) this.button1 = args.button1
      if (args.button2) this.button2 = args.button2
      if (args.hideButton2 && args.hideButton2 === true) this.button2 = ''
  }
  
  ngOnInit() {
    this.el.nativeElement.addEventListener("keydown", (event: KeyboardEvent) => {
      if (event.key == 'Enter') {
        event.preventDefault()
        this.onButton1()
      }
    });
  }

  onButton1() {
      this.dialogRef.close(this.button1);
  }

  onButton2() {
      this.dialogRef.close(this.button2);
  }
}
