import { SelectionModel } from '@angular/cdk/collections'
import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatRow, MatTable, MatTableDataSource } from '@angular/material/table';
import { MessageBoxService } from '../messagebox.service';
import * as _ from 'lodash';
import { MatButtonToggleChange, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

const generateId = function () {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random().toString(36).substr(2, 9);
  };

export interface ListData {
    value: number | string;
    text: string;
}

export type ArrowPosition = 'before' | 'after';

export interface ColumnDefinitionsModel {
    name: string;
    headerText: string;
    visible: boolean;
    sortable: boolean;
    showAsPills?: boolean;
    associatedList?: ListData[];
    arrowPosition:ArrowPosition;
    format?: (value: any, row: any) => void;
    template?: TemplateRef<unknown>;
}

export interface FilterColumnDefinitionsModel {
    column: string;
    value: string;
}
export interface FilterDefinitionsModel {
    label: string;
    filters: FilterColumnDefinitionsModel[];
    active: boolean;
    class?: any;
    filter?: (value: any, data: any) => [];
}

export interface ClickEventArg {
    event: string;
    rowData: Object;
    index: number;
}

export interface RowActionEventArg {
    event: string;
    rowData: Object;
}

export interface FilterEventArg {
    filter: FilterDefinitionsModel;
    data: [];
}

interface StateInformation {
    filter: string;
    sort: Sort | undefined
    pageIndex : number
}

@Component({
  selector: 'tsng-simple-grid',
  templateUrl: './simple-grid.component.html',
  styleUrls: ['./simple-grid.component.scss']
})
export class SimpleGridComponent implements OnInit, AfterViewInit, AfterContentInit {
  @ViewChild(MatSort, {static: true}) sort!: MatSort;
  @ViewChild(MatTable, {static: true}) matTable!: MatTable<any>;
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild('inputFilter', {static: true}) inputFilter!: ElementRef<HTMLInputElement>;
  @ViewChild('toggleFilter', {static: true}) toggleFilter!: MatButtonToggleGroup;
  // @ViewChild(CdkVirtualScrollViewport, { static: true }) viewPort!: CdkVirtualScrollViewport;

//   @Input() routerLink = undefined;
  @Input() id:string | undefined = undefined;
  @Input() data: any[] = [];
  @Input() width = '100%';
  @Input() showAction = true;
  @Input() showPager = true;
  @Input() disabled = false;
  @Input() loading = true;
  @Input() allowAdd = true;
  @Input() allowEdit = true;
  @Input() allowReorder = false;
  @Input() allowDuplicate = false;
  @Input() allowDelete = true;
  @Input() allowPrint = false;
  @Input() allowExport = false;
  @Input() showFilter = true;
  @Input() multiSelection = false;
  @Input() saveState = true;
  @Input() rowClass? : (row:any, el:MatRow) => string;
  @Input() pageSize = 25;
  @Input() pageIndex = 0;
  @Input() pageSizeOptions: number[] = [10, 25, 50, 100];
  @Input('columnDefinitions') columnDefs: ColumnDefinitionsModel[] = [];
  @Input() displayedColumns: string[] = [];
  @Input() quickFliterClasses?: any;
  @Input() quickFilters: FilterDefinitionsModel[] = [];
  public selectedFilter:any = _.find(this.quickFilters, {active:true});

  // Output
  @Output() rowclick = new EventEmitter<ClickEventArg>();
  @Output() rowDoubleclick = new EventEmitter<ClickEventArg>();
  @Output() onEdit = new EventEmitter<ClickEventArg>();
  @Output() onDelete = new EventEmitter<ClickEventArg>();
  @Output() onAdd = new EventEmitter<RowActionEventArg>();
  @Output() onDuplicate = new EventEmitter<ClickEventArg>();
  @Output() onFiltered = new EventEmitter<FilterEventArg>();
  @Output() onPrint = new EventEmitter<ClickEventArg>();
  @Output() onExport = new EventEmitter<any>();
  @Output() onReorder = new EventEmitter<any>();
  
  // Stuff pour les sélections
  selection = new SelectionModel<any[]>(true, []);
  @Input() selectOnRowClick = this.multiSelection;
  @Input() selected:any[] = []
  @Output() selectedChange = new EventEmitter<any[]>();
  @Output() onSelection = new EventEmitter<RowActionEventArg>();

  // Internal variables
  private _state: StateInformation = {
      filter : '',
      sort : undefined,
      pageIndex : 0
  }
  private _id_state = '';
  public dragDisabled = true;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataSource.data.forEach(row => this.selection.select(row));

      this.selected = _.clone(this.selection.selected)
      this.selectedChange.emit(this.selected);
  }
  onSelectionChange(event: any, element: any) {
      event ? this.selection.toggle(element) : null
      const selectEvent: RowActionEventArg = {
          event: event,
          rowData: element
      }
      this.onSelection.emit(selectEvent);
      this.selected = this.selection.selected.splice(0)
      this.selectedChange.emit(this.selected);
  }

  getActionColWidth(): number {
      let width = 25;
      if (this.allowDelete) width+=45
      if (this.allowEdit) width+=45
      if (this.allowDuplicate) width+=45
      if (this.allowPrint) width+=45
      return (width);
  }

  // ITEM_SIZE = 48;
  // offset: number = 0;
  dataSource!: MatTableDataSource<any>;

  applyQuickFilter2(filter: MatButtonToggleChange) {
    this.applyQuickFilter(filter.value);
  }

  applyQuickFilter(filter: FilterDefinitionsModel) {
      // Reset active
      this.quickFilters.forEach(f => (f.active = false));
      filter.active = true;

      // Faire le filtre à l'interne
      if (filter.filter === undefined) {
          if (filter.filters.length > 0) {
              this.dataSource.data = this.data.filter(r => {
                  let bOk = true;
                  filter.filters.forEach((f: FilterColumnDefinitionsModel) => {
                      if (r[f.column] !== undefined) {
                          switch (typeof r[f.column]) {
                              case 'number':
                                  bOk = bOk && r[f.column] == f.value;
                                  break;

                              case 'string':
                                  bOk =
                                      bOk &&
                                      r[f.column]
                                          .trim()
                                          .toLowerCase()
                                          .search(f.value.trim().toLowerCase()) >= 0;
                                  break;

                              default:
                                  break;
                          }
                      }
                  });

                  return bOk;
              });
              this.onFiltered.emit({ filter, data: this.dataSource.data } as FilterEventArg);
          } else {
              // Pas de fltres... on montre tout
              this.dataSource.data = this.data;
              this.onFiltered.emit({ filter, data: this.dataSource.data } as FilterEventArg);
          }
      } else {
          // Emettre l'event, le code externe s'occupe du data
          const ret = filter.filter(filter, this.data);
          this.dataSource.data = ret;
          this.onFiltered.emit({ filter, data: this.dataSource.data } as FilterEventArg);
      }
  }

  constructor(private mbox:MessageBoxService, private ref: ChangeDetectorRef) {
      if (this.quickFilters == undefined) {
          this.quickFilters = [];
      }
      if (this.data == undefined) {
          this.data = [];
      }
  }

  ngAfterContentInit() {
    // if (this.paginator) {
    //     this.dataSource.sort = this.sort;

    //     this.paginator.pageSize = this.pageSize
    //     this.dataSource.paginator = this.paginator;
    //     this.paginator._intl.firstPageLabel = 'Première page';
    //     this.paginator._intl.itemsPerPageLabel = 'Items par page';
    //     this.paginator._intl.lastPageLabel = 'Dernière page';
    //     this.paginator._intl.nextPageLabel = 'Prochaine page';
    //     this.paginator._intl.previousPageLabel = 'Page précédente';
    //     this.paginator._intl.getRangeLabel = (page: number, pageSize: number,length: number) => 
    //     {
    //         return `Page #${page + 1} - ${pageSize} sur ${length}`;
    //     };
    // }

    
  }

  ngAfterViewInit() {
    if (this.id && this.saveState) {
        const ss = localStorage.getItem(`${this._id_state}`);
        if (ss) {
            try {
                this._state = JSON.parse(ss);

                this.inputFilter.nativeElement.value = this._state.filter;

                if (this._state.filter) {
                    this.dataSource.filter = this._state.filter.trim().toLowerCase();
                }

                if (this._state.sort) {
                    const s:MatSortable = {
                        id: this._state.sort.active,
                        start : (this._state.sort.direction === 'asc' ? 'asc' : 'desc'),
                        disableClear : true
                    }
                    this.dataSource.sort?.sort(s)
                }

            } catch (error) {
                console.error('LocalStorage invalide', error, `${this._id_state}`, ss)
            }
        }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
        this.loading = true;
        if (changes.data.currentValue !== undefined && changes.data.currentValue.length > 0) {
            // S''assurer de rajouter notre id interne
            this.data = _.map(changes.data.currentValue, (v) => {
                return {__id__: generateId(),...v};
            });
            this.dataSource = new MatTableDataSource(this.data);
            
            if (this._state.filter) {
                this.dataSource.filter = this._state.filter.trim().toLowerCase();
            }
            
            if (this._state.pageIndex > 0) {
                this.paginator.pageIndex = this._state.pageIndex;
            }
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            
            if (this.selectedFilter) {
                this.applyQuickFilter(this.selectedFilter);
            }

            this.loading = false;
            this.ref.markForCheck();
        } else {
            this.data = [];
            if (this.dataSource) {
                this.dataSource.data = [];
            }
            if (changes.data.previousValue !== undefined || changes.data.isFirstChange() === false) {
                this.loading = false;
            }
            // this.ref.markForCheck();
        }
        this.loading = false;
        this.ref.markForCheck();
    } 

    if (changes.quickFilters) {
        this.selectedFilter = _.find(this.quickFilters, {active:true});
        if (this.dataSource) this.applyQuickFilter(this.selectedFilter);
    }

    if (changes.columnDefs) {
        // Ooops on modifie les colonnes!
        if (changes.columnDefs.currentValue && !changes.columnDefs.firstChange) {
            this.columnDefs = [...changes.columnDefs.currentValue];
            this.buildColumns()
        }
    }
  }

  buildColumns() {
      // On pousse notre __id__
      this.columnDefs.push({
        headerText: '__id__',
        visible: false,
        name: '__id__',
        sortable: false,
        arrowPosition : 'before'
    });

    if (this.showAction === true && this.columnDefs) {
        // On doit rajouter la colonne des actions
        const action = _.find(this.columnDefs, {name : '_action_'})
        if (!action) {
            this.columnDefs.push({
                headerText: 'Action',
                visible: true,
                name: '_action_',
                sortable: false,
                arrowPosition : 'before'
            });
        }
    }

    if (this.allowReorder === true && this.columnDefs) {
        // On doit rajouter la colonne du drag
        const action = _.find(this.columnDefs, {name : '_drag_'})
        if (!action) {
            this.columnDefs.unshift({
                headerText: 'Drag',
                visible: true,
                name: '_drag_',
                sortable: false,
                arrowPosition : 'before'
            });
        }

        // S'assurer de désactiver le "sort" des header, ça ne fait plus de sens
        for(const c of this.columnDefs) {
            c.sortable = false;
        }
    }

    if (this.multiSelection === true && this.columnDefs && this.columnDefs[0].name !== '_select_') {
        // On doit rajouter la colonne de sélection
        this.columnDefs.unshift({
            headerText: 'Selection',
            visible: true,
            name: '_select_',
            sortable: false,
            arrowPosition : 'before'
        });
    }

    if (this.columnDefs == undefined || this.columnDefs.length == 0) {
        // Auto générer les colonnes
        const autoDisplay = this.displayedColumns.length > 0 ? false : true;
        _.each(this.data[0], (f, k: any) => {
            this.columnDefs.push({
                headerText: k,
                name: k,
                visible: autoDisplay ? true : this.displayedColumns[k] ? false : true,
                sortable: true,
                arrowPosition : 'before'
            });
            if (autoDisplay) this.displayedColumns.push(k);
        });

    } else if (this.columnDefs.length && this.displayedColumns.length == 0) {
        // Composer la liste des colonnes à afficher selon les columnsDefs
        _.each(this.columnDefs, (cd: ColumnDefinitionsModel) => {
            if (cd.visible) {
                this.displayedColumns.push(cd.name);
            }
        });
    }

  }

  ngOnInit() {
    // Batir nos colonnes.
    this.buildColumns();
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.id) {
        this._id_state = `${this.id}_sg_state`;
    }
    if (this.paginator) {
        this.dataSource.sort = this.sort;

        this.paginator.pageSize = this.pageSize
        this.dataSource.paginator = this.paginator;
        this.paginator._intl.firstPageLabel = 'Première page';
        this.paginator._intl.itemsPerPageLabel = 'Items par page';
        this.paginator._intl.lastPageLabel = 'Dernière page';
        this.paginator._intl.nextPageLabel = 'Prochaine page';
        this.paginator._intl.previousPageLabel = 'Page précédente';
        this.paginator._intl.getRangeLabel = (page: number, pageSize: number,length: number) => 
        {
            return `Page #${page + 1} - ${pageSize} sur ${length}`;
        };
    }
  }

  RenderElement(element: any, cd: ColumnDefinitionsModel, row: any) {
      if (cd.associatedList) {
          return _.find(cd.associatedList, { value: element })?.text;
      } else {
          if (cd.format) {
              return cd.format(element, row);
          } else {
              return element;
          }
      }
  }

  getSortable() {
      return this.columnDefs.filter(i => i.sortable === true);
  }

  getNotSortable() {
      return this.columnDefs.filter(i => i.sortable === false && i.name !== '_select_' && i.name !== '_drag_' && i.name !== '__id__');
  }

  applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.saveState && this.id) {
          this._state.filter = filterValue;
          localStorage.setItem(`${this._id_state}`, JSON.stringify(this._state) );
      }
  }

  clearFilter() {
      this.inputFilter.nativeElement.value = '';
      this.dataSource.filter = '';
      if (this.saveState && this.id) {
          this._state.filter = '';
          localStorage.setItem(`${this._id_state}`, JSON.stringify(this._state) );
      }
  }  

  onClick(row: any, event: any, index: number) {
      if (!event.defaultPrevented) {
          event.preventDefault();
          _.each(_.filter(this.dataSource.data, {isSelected: true}), (e) => e.isSelected=false )
          row.isSelected = true;
        //   console.log('onClick1', this.dataSource.data);
          this.rowclick.emit({ event: 'click', rowData: row, index: index });
          if (this.selectOnRowClick && this.multiSelection) {
              this.selection.toggle(row);
              this.selected = this.selection.selected.splice(0)
              this.selectedChange.emit(this.selected);
          }
      }
  }

  onAddClick() {
      this.onAdd.emit();
  }

  refresh() {
      this.loading = false;
      this.ref.markForCheck();
  }

  action(row: any, type: string, event: any, index: number) {
      if (type === 'edit') {
          this.onEdit.emit({ event: type, rowData: row, index: index });
          event.preventDefault();
      } else if (type === 'duplicate') {
          this.mbox.open('Dupliquer cet item', 'Êtes-vous certain de vouloir dupliquer cet item?').subscribe(
              result => {
                  if (result === 'Ok') {
                      this.onDuplicate.emit({ event: type, rowData: row, index: index });
                  }
              }
          );
          event.preventDefault();
      } else if (type === 'delete') {
          this.mbox.ConfirmDelete().subscribe(
              result => {
                  if (result === 'Effacer') {
                      this.onDelete.emit({ event: type, rowData: row, index: index } as ClickEventArg);
                  }
              }
          );

          event.preventDefault();
      } else if (type === 'print') {
        this.onPrint.emit({ event: type, rowData: row, index: index });
        event.preventDefault();
      }
  }

  sortChanged(sort:Sort) {
      if (this.saveState && this.id) {
          this._state.sort = sort;
          localStorage.setItem(`${this._id_state}`, JSON.stringify(this._state) );
      }
  }

  pageChanged(page:PageEvent) {
      if (this.saveState && this.id) {
          this._state.pageIndex = page.pageIndex;
          localStorage.setItem(`${this._id_state}`, JSON.stringify(this._state) );
      }
  }

  onExportClick() {
      this.onExport.emit();
  }
  getRowClass(row:any, el:MatRow) {
      try {
        if (this.rowClass) {
            console.log('this.rowClass', this.rowClass)
            return this.rowClass(row, el);
        } else {
            return row.isSelected ? 'selected' : '';
        }
      } catch (error) {
        console.error('getRowClass error', error);
        return '';
      }
  }

  drop(event: CdkDragDrop<any>) {
    // Return the drag container to disabled.
    this.dragDisabled = true;

    const previousIndex = this.data.findIndex((d) => d === event.item.data);

    moveItemInArray(this.data, previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.data);
    this.onReorder.emit({event, data: this.data})
  }

}
